import React from "react";
import { Tabs } from "antd";
import DataResultOneDay from "./DataResultOneDay";
import DataResultCurrent from "./DataResultCurrent";

const batchId = {
  paytm: "paytm",
  bharatPe: "PCBA-BBID",
  hdfc: "PCBA-BID",
};
const Clients = ({ batchId }) => {
  const items = [
    {
      key: "1",
      label: "Today Live Data",
      children: <DataResultCurrent batchId={batchId} />,
    },
    {
      key: "2",
      label: "Data By Date",
      children: <DataResultOneDay batchId={batchId} />,
    },
  ];
  return <Tabs centered defaultActiveKey="1" items={items} />;
};
const DataResultDashboard = () => {
  const clients = [
    {
      key: "1",
      label: "Paytm",
      children: <Clients batchId={batchId.paytm} />,
    },
    {
      key: "2",
      label: "BharatPe",
      children: <Clients batchId={batchId.bharatPe} />,
    },
    {
      key: "3",
      label: "HDFC",
      children: <Clients batchId={batchId.hdfc} />,
    },
  ];

  return <Tabs centered defaultActiveKey="1" items={clients} />;
};

export default DataResultDashboard;
