import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Spin,
  Table,
  Upload,
  message,
  Modal,
} from "antd";

import axios from "axios";
import React, { useEffect, useState } from "react";
import TwoFactAuth from "../../TwoFactAuth/TwoFactAuth";
import { useSelector } from "react-redux";

const UploadDataProduction = () => {
  const [ApiData, setApiData] = useState([]);
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { email } = useSelector((state) => state.persistedReducer.user);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    const environmentType = "/getUploadFiles";
    axios
      .get(process.env.REACT_APP_API_URL + environmentType)
      .then((result) => {
        let data = result.data;
        let newArr = [];
        data.map((x, i) => {
          newArr.push({
            no: i + 1,
            file: x.name,
            date_time: new Date(x.createdAt).toLocaleString(),
            action: x,
            id: x._id,
            fileLink: x.link,
          });
        });
        setApiData(newArr);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const downloadFile = (id) => {
    const environmentType = "downloadData";
    ApiData.map((x) => {
      if (id == x.id) {
        window.location.href = `${process.env.REACT_APP_API_URL}/${environmentType}/${x.fileLink}?token=WgMLYoPjS56HqtD`;
      }
    });
  };

  const copyDownloadFileLink = (id) => {
    const environmentType = "downloadData";
    ApiData.map((x) => {
      if (id == x.id) {
        let copyText = `${process.env.REACT_APP_API_URL}/${environmentType}/${x.fileLink}?token=WgMLYoPjS56HqtD`;
        navigator.clipboard.writeText(copyText);
        messageApi.open({
          type: "success",
          content: "link has been copied to the clipboard",
        });
      }
    });
  };

  const handleDelete = (data) => {
    let data2 = {
      id: data._id,
      name: data.link,
    };
    setLoading(true);
    const environmentType = "/deleteUploadFiles";

    axios
      .post(process.env.REACT_APP_API_URL + environmentType, data2)
      .then((result) => {
        console.log(result.data);
        messageApi.open({
          type: "success",
          content: "File has been deleted",
        });
        getData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onFinish = () => {
    setLoading(true);
    let data = new FormData();
    data.append("name", values.name);
    data.append("myfile", values.file.file.originFileObj);
    const environmentType = "/UploadFiles";
    axios
      .post(process.env.REACT_APP_API_URL + environmentType, data)
      .then((result) => {
        console.log(result.data);
        getData();
        messageApi.open({
          type: "success",
          content: "file uploaded successfully",
        });
        setLoading(false);
        setIsModalOpen(false);
        form.resetFields();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onFinishFailed = ({ errorFields }) => {};

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
    },
    {
      title: "Date & Time",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (data) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <DownloadOutlined
              onClick={() => downloadFile(data._id)}
              title="Copy Link"
              style={{
                fontSize: "14px",
                color: "#167bff",
                marginRight: "10px",
              }}
            />
            <CopyOutlined
              onClick={() => copyDownloadFileLink(data._id)}
              title="Copy Link"
              style={{
                fontSize: "14px",
                color: "green",
                marginRight: "10px",
              }}
            />
            <Popconfirm
              title="Are you sure"
              onConfirm={() => handleDelete(data)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined style={{ fontSize: "14px", color: "red" }} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handle2FA = (verified) => {
    if (!verified) {
      messageApi.open({
        type: "warning",
        content: "Verification failed, try again",
      });
      return;
    }
    onFinish();
  };

  const verifyUser = (values) => {
    if (!values.file) {
      messageApi.open({
        type: "warning",
        content: "Please select a file",
      });
      return;
    }
    setLoading(true);
    axios
      .post(`/email/otp`, {
        email,
      })
      .then(() => {
        setValues(values);
        setIsModalOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

  return (
    <div>
      <TwoFactAuth
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handle2FA={handle2FA}
      />
      <Spin spinning={loading}>
        {contextHolder}
        <div style={{ marginBottom: "2rem" }}>
          <h3>Upload Files to Production</h3>
        </div>
        <div>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={verifyUser}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelCol={{
              span: 2,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            <Form.Item
              label="File Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your File Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="File" name="file">
              <Upload action="/upload.do" listType="picture-card">
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Select File
                  </div>
                </div>
              </Upload>
            </Form.Item>

            <Form.Item
              labelCol={{
                span: 2,
              }}
              wrapperCol={{
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Upload File
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div style={{ marginTop: "25px" }}>
          <Table columns={columns} dataSource={ApiData} />
        </div>
      </Spin>
    </div>
  );
};

export default UploadDataProduction;
