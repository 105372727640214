import React, { useState } from "react";
import { Button, Spin, notification } from "antd";
import OtpInput from "react18-input-otp";
import { useSelector } from "react-redux";
import axios from "axios";

export default function TwoFactEmailAuth({ next }) {
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const { email } = useSelector((state) => state.persistedReducer.user);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    axios
      .post(`/email/verify-otp`, {
        email: email,
        otp: code,
      })
      .then((res) => {
        setError(null);
        setCode("");
        next();
      })
      .catch((err) => {
        setError("Invalid OTP Code");
      });
  };

  const handleChange = (code) => setCode(code);

  const resendEmailOTP = () => {
    setLoading(true);
    axios
      .post(`/email/otp`, {
        email: email,
      })
      .then(({ data }) => {
        setLoading(false);
        openNotification("success", data?.message || "OTP sent success");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const openNotification = (type, message) => {
    api[type]({
      message,
    });
  };

  return (
    <>
      {contextHolder}

      <h3 className="text-center text-[24px] font-medium text-[#1E2328] leading-[32px]">
        Email Verification
      </h3>

      <p
        className="text-center text-[14px] font-medium text-[#898B8F] leading-[20px] w-80 mx-auto"
        style={{ marginTop: "8px" }}
      >
        Enter the verification code sent to <strong>{email}</strong>
      </p>

      <div
        className="modal-action flex items-center justify-center max-h-96"
        style={{ marginTop: "8px" }}
      >
        <form
          method="dialog"
          className="flex items-center justify-center flex-col"
        >
          <Spin spinning={loading}>
            <div className="otp-input-container mb-6">
              <OtpInput
                className="otp-input border p-2 mx-2 rounded text-center"
                value={code}
                onChange={handleChange}
                numInputs={6}
                isInputNum
                shouldAutoFocus
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  fontSize: "20px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
          </Spin>
          <p className="text-rose-600 text-[12px] h-[1rem] text-center mb-4">
            {error}
          </p>
        </form>
      </div>

      <div
        className="max-w-[26.5rem] mx-auto text-center"
        style={{ marginTop: "8px" }}
      >
        <Button
          className="btn bg-black w-full text-white text-[17px] font-medium rounded-md py-3 mb-6"
          onClick={handleSubmit}
        >
          Submit
        </Button>

        <div style={{ marginTop: "8px" }}>
          <h3 className="text-[#6B7280] font-medium text-[16px]">
            Didn’t receive the OTP?{" "}
            <span
              className="text-black font-medium cursor-pointer underline"
              onClick={resendEmailOTP}
            >
              Resend
            </span>
          </h3>
        </div>
      </div>
    </>
  );
}
