import React, { useState } from "react";
import { Steps, theme, Modal } from "antd";
import TwoFactEmailAuth from "./TwoFactEmailAuth";
import TwoFactGoogleAuth from "./TwoFactGoogleAuth";
import "./style.css";

export default function TwoFactAuth({
  handle2FA,
  isModalOpen,
  setIsModalOpen,
}) {
  const [current, setCurrent] = useState(0);
  const { token } = theme.useToken();

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const handleSubmitfinal = (verified) => {
    handle2FA(verified);
    setCurrent(0);
    setIsModalOpen(verified);
  };

  const steps = [
    {
      title: "",
      content: <TwoFactEmailAuth next={next} />,
    },
    {
      title: "",
      content: <TwoFactGoogleAuth handleSubmitfinal={handleSubmitfinal} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    lineHeight: "20px",
    textAlign: "center",
    color: token.colorTextTertiary,
    borderRadius: token.borderRadiusLG,
    marginTop: 16,
  };

  return (
    <>
      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
      >
        <div className="modal-box max-w-[34rem]">
          <Steps current={current} items={items} />
          <div style={contentStyle}>{steps[current].content}</div>
        </div>
      </Modal>
    </>
  );
}
