import React, { useState } from "react";
import { Button } from "antd";
import OtpInput from "react18-input-otp";
import { useSelector } from "react-redux";
import axios from "axios";

export default function TwoFactGoogleAuth({ handleSubmitfinal }) {
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const { google_secret } = useSelector((state) => state.persistedReducer.user);

  const handleSubmit = () => {
    axios
      .post(`/google/verify-otp`, {
        secret: google_secret,
        token: code,
      })
      .then(({ data }) => {
        if (data.verified) {
          setError(null);
          setCode("");
          handleSubmitfinal(true);
        } else {
          handleSubmitfinal(false);
          setError("Invalid OTP Code");
        }
      })
      .catch((err) => {
        const error = err?.response?.data?.error;
        setError(
          "Invalid OTP Code or Please Generate and Scan New Google Authenticator QR in Settings"
        );
      });
  };

  const handleChange = (code) => setCode(code);

  return (
    <>
      <h3 className="text-center text-[29px] font-medium text-[#1E2328] leading-[1.5] my-2">
        User Verification
      </h3>

      <p
        className="py-1 text-center text-[14px] font-medium text-[#898B8F] leading-[1.5] w-80 mx-auto"
        style={{ marginTop: "8px" }}
      >
        Enter the confirmation code displayed in the Google Authenticator app
      </p>

      <div
        className="modal-action flex items-center justify-center max-h-96"
        style={{ marginTop: "8px" }}
      >
        <form
          method="dialog"
          className="flex items-center justify-center flex-col"
        >
          <div className="otp-input-container mb-6">
            <OtpInput
              className="otp-input border p-2 mx-2 rounded text-center"
              value={code}
              onChange={handleChange}
              numInputs={6}
              placeholder=""
              errorStyle="error"
              successStyle="success"
              shouldAutoFocus
              inputStyle={{
                width: "3rem",
                height: "3rem",
                fontSize: "20px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <p
            className="text-rose-600 text-[12px] h-[1rem]"
            style={{ color: "red" }}
          >
            {error}
          </p>
        </form>
      </div>

      <div
        className="mt-6 max-w-[26.5rem] mx-auto text-center"
        style={{ marginTop: "8px" }}
      >
        <Button
          className="btn bg-black w-full text-white text-[17px] font-medium rounded-md py-3"
          type="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}
